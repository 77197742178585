.loginSection
  display: block
  margin: 50px auto
  background: #faed46
  height: 330px
  border-style: solid
  border-width: medium
  border-radius: 2px
  width: 300px

  .title
    margin-top: 10px
    font-size: x-large
    font-weight: bold
  form
    margin: 10px

    .formInput
      margin: 10px

      label
        width: fit-content
        display: block

      input
        display: block
        margin-top: 10px
        width: 230px
        padding: 10px
        border-style: solid
        border-color: black
        border-width: thin
        border-radius: 2px


      input[type='text'], textarea
        background-color: white!important

      input[type='submit']

  .button
    display: inline-block
    width: max-content
    height: -moz-fit-content
    height: fit-content
    padding: 10px 15px
    margin: 15px 10px
    background: white
    color: black
    font-family: inherit
    border: none
    font-size: larger
    border-style: solid
    border-width: thin

    &:hover
      background: #faed46
      cursor: pointer
      color: black
      border-style: solid
      border-width: thin
      border-radius: 2px

  .passwordRecovery
    text-decoration: underline
    font-family: none
