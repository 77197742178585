.body {
  width: 100%;
  min-height: 600px;
  height: 100%;
  background: white;
}
.body a {
  text-decoration: none;
}

