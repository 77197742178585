.logo {
  display: inline-block;
  width: 80%;
}
.logo img {
  display: block;
  width: 200px;
  /* width: 15%; */
  /* height: 15%; */
  margin: 10px;
}

