.buttonContainer {
  display: flex;
  margin: 20px;
  width: fit-content;
  height: fit-content;
}
.buttonContainer .button {
  margin: 13px;
  width: max-content;
  background: aliceblue;
  padding: 15px;
}

