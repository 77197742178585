.button {
  display: inline-block;
  width: max-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px 15px;
  margin: 15px 10px;
  border-radius: 2px;
  border-style: solid;
  border-width: thin;
  background: white;
  color: black;
}
.button:hover {
  background: #faed46;
  cursor: pointer;
}

