.footer
  width: 100%
  height: 100%
  background: white
  margin: 100px auto
  font-family: initial

  .footerText
    display: inline-block
    cursor: pointer
    margin: 2px 5px
    padding: 2px 5px
    width: 50px


    &:hover
      font-weight: bolder

    a:link
      text-decoration: inherit
      color: inherit
      cursor: pointer

    a:visited
      text-decoration: inherit
      color: inherit
      cursor: pointer


