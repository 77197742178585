.dashboardSection {
  display: block;
  width: 100%;
  height: 100%;
}
.dashboardSection .title {
  font-weight: bold;
  font-size: xx-large;
  margin: 20px;
}
.dashboardSection .subtitle {
  font-weight: normal;
  font-size: larger;
  margin: 20px;
}
.dashboardSection .pitchdeckContainer {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
}
.dashboardSection .pitchdeckContainer .pitchdeck {
  display: inline-block;
  margin: 20px;
  width: 550px;
  height: fit-content;
  background: rgba(246, 231, 205, 0.29);
  border-style: solid;
}
.dashboardSection .pitchdeckContainer .pitchdeck:hover {
  background: rgb(246, 231, 205);
  cursor: pointer;
}
.dashboardSection .pitchdeckContainer .pitchdeck .title {
  font-weight: bold;
  font-size: x-large;
  margin: 20px;
}
.dashboardSection .pitchdeckContainer .pitchdeck .poster {
  width: 400px;
  height: 550px;
  margin: 30px auto;
  border-style: solid;
  border-width: medium;
  object-fit: scale-down;
}
.dashboardSection .pitchdeckContainer .pitchdeck .poster img {
  width: 400px;
  height: 550px;
  object-fit: fill;
}
.dashboardSection .pitchdeckContainer .pitchdeck .specContainer {
  width: fit-content;
  margin: 20px auto;
  text-align: left;
}
.dashboardSection .pitchdeckContainer .pitchdeck .specContainer .spec {
  margin: 5px;
}
.dashboardSection .pitchdeckContainer .pitchdeck .specContainer .spec span {
  font-weight: bold;
}
.dashboardSection .searchBar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.dashboardSection .searchBar .searchInput {
  width: 300px;
  height: 30px;
  border-radius: 10px;
  border-style: solid;
  border-color: black;
  border-width: thin;
  padding: 5px 10px;
}
.dashboardSection .searchBar .searchIcon {
  width: 50px;
  height: 50px;
}

