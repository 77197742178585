
.userAvatar

  height: max-content
  width: max-content



.avatarStyle
  border-radius: 50%
  cursor: pointer
  background: aliceblue
  width: 50px
  height: 50px
  margin: 10px 10px 0px 0px
  display: flex
  flex-direction: column
  flex-wrap: nowrap
  align-content: stretch
  justify-content: space-around
  align-items: stretch
  border-style: solid
  font-size: larger
  margin: 10px 10px 10px 0px

  &:hover
    background: #f9fcff

.avatarStyleClicked
  cursor: pointer
  background: aliceblue
  width: 50px
  height: 50px
  display: flex
  flex-direction: column
  flex-wrap: nowrap
  align-content: stretch
  justify-content: space-around
  align-items: stretch
  border-style: solid
  border-radius: 50% 50% 0px 0px
  border-bottom-style: none
  font-size: larger
  margin: 10px 10px 10px 0px

  &:hover
    background: #f9fcff

.menuStyle
  display: block
  position: absolute
  border-radius: 10px 10px 10px 10px
  margin-top: -10px


#hidden
  display: none


.menuItemStyle
  cursor: pointer
  padding: 10px
  background: aliceblue
  width: 100px
  border-style: solid
  font-size: small
  text-decoration: none
  &:hover
    background: #f9fcff

.menuItemStyleClicked
  display: block
  position: absolute
  border-style: solid
  border-radius: 0px 10px 10px
  margin-right: 20px

  &:hover
    background: #f9fcff

#top
  border-radius: 0px 10px 0px 0px
  border-bottom-style: none

#bottom
  border-radius: 0px 0px 10px 10px


