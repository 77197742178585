.buttonContainer
  display: flex
  margin: 20px
  width: fit-content
  height: fit-content

  .button
    margin: 13px
    width: max-content
    background: aliceblue
    padding: 15px

    &:hover