.footer {
  width: 100%;
  height: 100%;
  background: white;
  margin: 100px auto;
  font-family: initial;
}
.footer .footerText {
  display: inline-block;
  cursor: pointer;
  margin: 2px 5px;
  padding: 2px 5px;
  width: 50px;
}
.footer .footerText:hover {
  font-weight: bolder;
}
.footer .footerText a:link {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}
.footer .footerText a:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

