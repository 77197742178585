.dashboardSection
  display: block
  width: 100%
  height: 100%

  .dashboardTitle

  .title
    font-weight: bold
    font-size: xx-large
    margin: 20px

  .subtitle
    font-weight: normal
    font-size: larger
    margin: 20px

  .pitchdeckContainer
    width: 100%
    height: 100%
    display: block
    margin: 0 auto
    flex-direction: row
    flex-wrap: wrap

    .pitchdeck
      display: inline-block
      margin: 20px
      width: 550px
      height: fit-content
      background: rgba(246, 231, 205, 0.29)
      border-style: solid

      &:hover
        background: rgb(246, 231, 205)
        cursor: pointer

      .title
        font-weight: bold
        font-size: x-large
        margin: 20px

      .poster
        width: 400px
        height: 550px
        margin: 30px auto
        border-style: solid
        border-width: medium
        object-fit: scale-down

        img
          width: 400px
          height: 550px
          object-fit: fill

      .specContainer
        width: fit-content
        margin: 20px auto
        text-align: left

        .spec
          margin: 5px


          span
            font-weight: bold



  .searchBar
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    align-content: center
    justify-content: center
    align-items: center

    .searchInput
      width: 300px
      height: 30px
      border-radius: 10px
      border-style: solid
      border-color: black
      border-width: thin
      padding: 5px 10px

    .searchIcon
      width: 50px
      height: 50px